<template>
	<div class="container-fluid animatedParent animateOnce p-0">
		<!-- main content -->
	
		<header class="white pt-3 relative shadow">
			<div class="container-fluid">
				<div class="row p-t-b-10 ">
					<div class="col">
						<div class="pb-3">
							<div class="image mr-3  float-left">
								<!-- <img class="user_avatar no-b no-p" :src="customer.img_url" alt="User Image"> -->
								<figure class="avatar avatar-xl">
									<img :src="customer.img_url" alt="" @error="errorLoadImg">
								</figure>
							</div>
							<div>
								<h5 class="p-t-10">
								{{customer.customer_name}}  
								<span  :class="[ 'ml-1',{'text-success' : customer.enabled == 1, 'text-danger':customer.enabled == 0, 'text-dark':customer.enabled == -1}]"><i class="icon-security2"></i></span>
								</h5>
								<!-- {{customer.customer_sn}} -->
								<h6><i class="icon-user"></i> {{customer.staff_no}}</h6>
								 <!-- <i v-if="customer.openid!=null&&customer.openid!=''" class="icon icon-wechat" style="color: green"></i> -->
								<h6><i class="icon-mobile"></i> {{customer.phone}} </h6>
							</div>
						</div>
					</div>
				</div>
				
			  <div class="row">
				  <ul class="nav nav-material responsive-tab" id="v-pills-tab" role="tablist">
					  <li>
						  <a class="nav-link active" id="v-pills-tab1-tab" data-toggle="pill" href="#v-pills-tab1" role="tab" aria-controls="v-pills-tab1">
							  <i class="icon icon-home2"></i>会员资料
						  </a>
					  </li>
					 <!-- <li>
						  <a class="nav-link" id="v-pills-recharge-tab" data-toggle="pill" href="#v-pills-recharge" role="tab" aria-controls="v-pills-payments" aria-selected="false"><i class="icon icon-money"></i>充值</a>
					  </li> -->
					  <li @click="getOrderList">
							<a class="nav-link" id="v-pills-order-tab" data-toggle="pill" href="#v-pills-order" role="tab" aria-controls="v-pills-payments" aria-selected="false"><i class="icon icon-list"></i>消费记录</a>
					  </li>
					  <li @click="getRechargeOrderList">
						  <a class="nav-link" id="v-pills-log-tab" data-toggle="pill" href="#v-pills-log" role="tab" aria-controls="v-pills-timeline" aria-selected="false"><i class="icon icon-document-list"></i>充值记录</a>
					  </li>
					  <li  v-if="outlet_level==1">
						  <a @click="editCustomerReady" class="nav-link" id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings" role="tab" aria-controls="v-pills-settings" aria-selected="false"><i class="icon icon-edit"></i>修改资料</a>
					  </li>
				  </ul>
				  
			  </div>
				
				
			</div>
		</header>
		
		
		<div class="container-fluid animatedParent animateOnce my-3">
			<div class="animated fadeInUpShort go">
		   <div class="tab-content" id="v-pills-tabContent">
			   <!-- 资料 tabcontent -->
			   <div class="tab-pane fade show active" id="v-pills-tab1" role="tabpanel" aria-labelledby="v-pills-home-tab">
				   <div class="row">
					   <div class="col-md-4">
						   <div class="card ">
				
							   <ul class="list-group list-group-flush">
								   <li class="list-group-item"><i class="icon icon-class"></i><strong class="s-12">级别</strong> <span class="float-right s-12">{{customergroup.name}}</span></li>
								  <!-- <li class="list-group-item"><i class="icon icon-mobile"></i><strong class="s-12">手机号码</strong> <span class="float-right s-12">{{customer.phone}}</span></li>
								   <li class="list-group-item"><i class="icon icon-credit-card"></i><strong class="s-12">卡号</strong> <span class="float-right s-12">{{customer.customer_sn}}</span></li> -->
								   <li class="list-group-item"><i class="icon icon-date_range"></i><strong class="s-12">创建日期</strong> <span class="float-right s-12">{{customer.create_date}}</span></li>
								   <li class="list-group-item"><i class="icon icon-date_range"></i> <strong class="s-12">过期日期</strong> <span class="float-right s-12">{{customer.expired_date}}</span></li>
								   <li class="list-group-item"><i class="icon icon-room"></i> <strong class="s-12">开卡店铺</strong> <span class="float-right s-12">{{customer.outlet_name}}</span></li>
							   </ul>
						   </div>
						   
							
					   </div>
					   <div class="col-md-8">
				
						   <div class="row">
							   <div class="col-lg-6">
								   
								   <div class="card rm-3" style="height: 181px !important;">
									   <div class="p-4">
										  <div class="float-right" v-if="outlet_level==1">
											   <!-- <span class="icon-account_balance s-48"></span> -->
											   <button @click.stop="rechargeReady(0)" v-bind:disabled="customer.enabled == -1" type="button" class="btn btn-primary btn-sm r-15 ml-3">充值</button>
											   <button @click.stop="rechargeReady(1)" type="button" class="btn btn-dark btn-sm r-15 ml-3">退款</button>	
												
												<div class="ml-3 mt-3">
													<div class="s-14">补贴:{{customergroup.subsidy}}元/月</div>
													<div class="">{{customergroup.clear_flag==1?'月末清零':'月末不清零'}}</div>
												</div>
										   </div>
										   <div class="=">余额</div>
										   <div class="mt-3 s-24 text-black">{{customer.balance}} {{setting.customer_balance_type==1?'次':'元'}}</div>
										   <div>{{outlet_level==1?'本金':'本金'}}{{customer.recharge_balance}}, {{outlet_level==1?'补贴':'赠送'}}{{customer.gift_balance}}</div>
									   
											
									   </div>
								   </div>
								   
								   <!-- <div class="card r-3 mt-3">
									   <div class="p-4">
										   <div class="float-right">{{customergroup.name}}</span>
										   </div>
										   <div class="">补贴</div>
										   <div class="mt-3 s-24">{{customergroup.subsidy}}元/月</div>
										   <div>{{customergroup.clear_flag==1?'月末清零':'月末不清零'}}</div>
									   </div>
								   </div> -->
							   </div>
							   <!-- <div class="col-lg-4">
								   
								</div> -->
							   <div class="col-lg-6">
								   
								   <!-- <div v-if="outlet_level==0"
								   class="card rm-3" style="height: 181px !important;">
									   <div class="p-4">
										   <div class="=">优惠券</div>
										   <div class="mt-3 s-24 text-black">10张</div>
										   <div></div>
									   </div>
								   </div> -->
								   
								   <div class="card"  v-if="outlet_level==1">
									   <div class="card-header white">
										   <strong> 状态 </strong>
									   </div>
									   <div class="card-body p-0">
										   <ul class="list-group no-b">
											   <li class="list-group-item">
											       正常 
											       <div class="material-switch float-right">
											           <input @click="onstatechange(1)"  id="someSwitchOptionDefault" name="someSwitchOption001" type="checkbox"  :checked="customer.enabled == 1">
											           <label for="someSwitchOptionDefault" class="bg-primary"></label>
											       </div>
											   </li>
											   <li class="list-group-item">
											       挂失
											       <div class="material-switch float-right">
											           <input @click="onstatechange(0)" id="someSwitchOptionPrimary" name="someSwitchOption001" type="checkbox" :checked="customer.enabled == 0">
											           <label for="someSwitchOptionPrimary" class="bg-primary"></label>
											       </div>
											   </li>
											   <li class="list-group-item">
											         注销
											       <div class="material-switch float-right">
											           <input @click="onstatechange(-1)" id="someSwitchOptionSuccess" name="someSwitchOption001" type="checkbox" :checked="customer.enabled == -1">
											           <label for="someSwitchOptionSuccess" class="bg-primary"></label>
											       </div>
											   </li>
											   
										   </ul>
									   </div>
								   </div>
								   
								   
							   </div>
							   
						   </div>
				
						   <div class=" row my-3">
						               
						    </div>
						   
					   </div>
				   </div>
			   </div>
			   <!-- //资料 tabcontent -->
			   
			   
			   <!-- 充值 tabcontent -->
			   <div class="tab-pane fade" id="v-pills-recharge" role="tabpanel" aria-labelledby="v-pills-payments-tab">
			   
			   </div>						   
				<!-- //充值 tabcontent -->						   
			   
			   <!-- 订单 tabcontent -->
			   <div class="tab-pane fade" id="v-pills-order" role="tabpanel" aria-labelledby="v-pills-payments-tab">
				   <div class="row">
					   <div class="col-md-12">
						   <div class="card no-b">
							   <div class="card-header white b-0 p-3">
								   <h4 class="card-title">消费记录(订单,仅显示本月）</h4>
								   <small class="card-subtitle mb-2 text-muted"> 共 {{orderlist.length}} 笔</small>
							   </div>
							   <div class="collapse show" id="invoiceCard">
								   <div class="card-body p-0">
									   <div class="table-responsive">
										   <table id="recent-orders" class="table table-hover mb-0 ps-container ps-theme-default">
											   <thead class="bg-light">
											   <tr>
												   <th>时间</th>
												   <th>单号#</th>
												   <th>店铺</th>
												   <!-- <th>状态</th> -->
												   <th>金额</th>
											   </tr>
											   </thead>
											   <tbody>
											   <tr v-bind:key="index" v-for="(item,index) in orderlist">
												   <td>{{item.sale_date}}</td>
												   <td><a href="#">{{item.sale_order_no}}</a></td>
												   <td>{{item.outlet_name}}</td>
												   <!-- <td><span class="badge badge-success">Paid</span></td> -->
												   <td>￥ {{item.total_price}}</td>
											   </tr>
											  
											   </tbody>
										   </table>
									   </div>
								   </div>
							   </div>
						   </div>
					   </div>
				   </div>
				
			   </div>
			   <!-- //订单 tabcontent -->
			   
			   <!-- 充值记录  tabcontent -->
			   <div class="tab-pane fade" id="v-pills-log" role="tabpanel" aria-labelledby="v-pills-timeline-tab">
				   <div class="row">
					   <div class="col-md-12">
						   
						   <div class="card no-b">
							   <div class="card-header white b-0 p-3">
								   <h4 class="card-title">充值记录</h4>
								   <small class="card-subtitle mb-2 text-muted"> 共 {{rechargeorderlist.length}} 笔</small>
							   </div>
							   <div class="collapse show" id="invoiceCard">
								   <div class="card-body p-0">
									   <div class="table-responsive">
										   <table id="recent-orders" class="table table-hover mb-0 ps-container ps-theme-default">
											   <thead class="bg-light">
											   <tr>
												   <th>时间</th>
												   <th>类型</th>
												   <th>现金(元)</th>
												   <th>补贴(元)</th>
												   <th>合计(元)</th>
												   <th>来源</th>
												   <th>说明</th>
											   </tr>
											   </thead>
											   <tbody>
											   <tr v-bind:key="index" v-for="(item,index) in rechargeorderlist">
												   <td>{{item.create_date}}</td>
												   <!-- <td><a href="#">INV-281281</a></td> -->
												   <td>
													   <span v-if="item.order_type==1" class="badge badge-danger r-20"> 退款</span>
													   <span v-if="item.order_type==0" class="badge badge-light r-20">充值</span>
													</td>
												   <td>{{item.total_price}}</td>
												   <td>{{item.gift}}</td>
												   <td>{{item.total_amount}}</td>
												   <td>{{item.app_way=='PC'?'后台':'自助'}}</td>
												   <td>{{item.remark}} {{item.description}}</td>
											   </tr>
											  
											   </tbody>
										   </table>
									   </div>
								   </div>
							   </div>
						   </div>
						  
					   </div>
					   <!-- /.col -->
				   </div>
			   </div>
			   <!-- //充值记录  tabcontent -->
			   
			   <!-- 修改  tabcontent -->
			   <div class="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
				   <div class="card mt-3 shadow">
				   	<div class="card-header white b-0 p-3">
					   <h4 class="card-title">修改资料</h4>
					   <small class="card-subtitle mb-2 text-muted">余额不可修改，请通过充值变更.</small>
				   	</div>
				   	<div class="card-body">
				   		<div class="needs-validation" novalidate="">
				   			<div class="form-row">
				   				<div class="col-md-3 mb-3">
				   					<label for="">级别</label>
				   					<select class="form-control" v-model="selectedCustomer.group_id">
				   						<option v-bind:key="index" :value="item.id"  v-for="(item,index) in customergrouplist">{{item.name}}</option>
				   					</select>
				   				</div>
								
								<div class="col-md-3 mb-3">
				   					<label for="validationCustom01">会员名</label>
				   					<input v-model="selectedCustomer.customer_name" type="text" class="form-control"  placeholder="会员名">
				   					<div class="valid-feedback">
				   						Looks good!
				   					</div>
				   				</div>
								
								<div class="col-md-3 mb-3">
									<label >工号</label>
									<input readonly="true"  v-model="selectedCustomer.staff_no" type="text" class="form-control"  placeholder="工号">
									<small class="form-text text-muted">
										不可修改，唯一，不可重复
									</small>
								</div>
								
				   				<div class="col-md-3 mb-3">
				   					<label for="validationCustomUsername">手机</label>
				   					<input v-model="selectedCustomer.phone_number" type="number" class="form-control" placeholder="手机号码">
				   					<small class="form-text text-muted">
				   						非常重要，手机号必须唯一
				   					</small>
				   				</div>
								
				   				
				   				
				   			</div>
				   			<div class="form-row">
								<div class="col-md-3 mb-3">
									<label for="">卡号</label>
									<input v-model="selectedCustomer.customer_code" type="number" class="form-control" placeholder="卡号">
									<small class="form-text text-muted">
										卡面号码
									</small>
								</div>
								<div class="col-md-3 mb-3">
									<label for="">卡片芯片ID</label>
									<input v-model="selectedCustomer.customer_sn" type="text" class="form-control" placeholder="卡片芯片ID">
									<small class="form-text text-muted">
										卡片内置芯片ID（刷卡可获得）
									</small>
								</div>
								
				   				<div class="col-md-3 mb-3">
				   					<label for="validationCustom03">余额</label>
				   					<input readonly="true" v-model="selectedCustomer.balance" type="number" class="form-control" placeholder="余额">
				   					<small class="form-text text-muted">
				   						余额不可更改，如需要增加，请充值
				   					</small>
				   				</div>
								<div class="col-md-3 mb-3">
									<label for="validationCustom02">密码</label>
									<input v-model="selectedCustomer.password" type="text" class="form-control" placeholder="会员密码">
									<small class="form-text text-muted">
										不设置请留空
									</small>
								</div>
				   				
				   				
				   					<!-- <div class="col-md-3 mb-3">
				   						<label for="validationCustom05">状态</label>
				   						<br>
				   						
				   						<div class="mt-1">
				   							<input v-model="customer.enabled" id="status_1" type="radio" name="status" value="1" class="">
				   							正常
				   							<input v-model="customer.enabled" id="status_0" type="radio" name="status" value="0" class="">
				   							挂失
				   						
				   							<input v-model="customer.enabled" id="status__1" type="radio" name="status" value="-1" class="">
				   							注销
				   						</div>	
				   					</div> -->
				   				</div>
								
								
								<div class="form-row">
									<div class="col-md-3 mb-3">
											<label for="validationCustom04">过期日期</label>
											<!-- <div class="input-group">
												<input v-model="customer.expired_date" type="text" id="expiry_date" class="date-time-picker form-control">
												<span class="input-group-append">
													<span class="input-group-text add-on white">
														<i class="icon-calendar"></i>
													</span>
												</span>
											</div> -->
											<br>
											<date-picker
												  v-model="selectedCustomer.expired_date"
												  format="YYYY-MM-DD"
												  value-type="format" 
												  input-class="form-control" 
												></date-picker>
											<small class="form-text text-muted">
												不填写表示永不过期
											</small>
										</div>
								</div>	
								
								<div class="form-row">
									<div class="col-md-6 mb-3">
										<label for="">会员标签TAG</label>
										<br>
										<!-- <tags-input v-model='tags'/> -->
										
										<input v-model="customer.tag" type="text" class="tags-input form-control" >
										   
										<small class="form-text text-muted">
											输入完一个标签后，按回车，最多5个
										</small>
									</div>
								</div>
				   				
				   				<div class="form-row" style="display: none;;">
				   					<div class="col-md-3 mb-3">
				   						<label for="validationCustom05">状态</label>
				   						<br>
				   						
				   						<div class="mt-1">
				   							<input v-model="customer.enabled" id="status_1" type="radio" name="status" value="1" class="">
				   							正常
				   							<input v-model="customer.enabled" id="status_0" type="radio" name="status" value="0" class="">
				   							挂失
				   						
				   							<input v-model="customer.enabled" id="status__1" type="radio" name="status" value="-1" class="">
				   							注销
				   						</div>	
				   					</div>
				   				</div>	
				   			
				   			<button @click="remove" type="button" class="btn btn-danger mr-2"> 删除 </button>
				   			<button @click="save" :disabled="disabled" type="button" class="btn btn-primary"> 保存修改 </button>
				   		</div>
				   
				   
				   	</div>
				   </div>
			   </div>
			   <!-- //修改  tabcontent -->
			   
		   </div>
	   </div>
		</div>
	
		<!-- //main content -->
		
		<!-- 充值 -->
		<div class="modal fade" id="cModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
		  <div class="modal-dialog">
		    <div class="modal-content">
		      <div class="modal-header">
		        <h5 class="modal-title" id="exampleModalLabel">{{order_type==1?'退款':'充值'}}</h5>
		        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
		          <span aria-hidden="true">&times;</span>
		        </button>
		      </div>
		      <div class="modal-body">
		        
				<div class="m-3">
					<div class="form-group">
						<label>现金(元)</label>
						<input v-model="rechargeorder.amount" class="form-control form-control-lg" placeholder="输入金额" type="number">
						<small v-if="order_type==1">
							最多可退{{customer.recharge_balance}}元
						</small>
					</div>
					<!-- <div class="form-group" v-if="setting.customer_balance_type == 1">
						<label>充值次数</label>
						<input v-model="rechargeorder.times" class="form-control form-control-lg" placeholder="输入充值次数" type="number">
					</div>
					<div class="form-group form-check">
					   <label class="form-check-label">
						   <input v-model="rechargeorder.is_gift" value="1" class="form-check-input" type="checkbox"> 补贴
					   </label>
					</div> -->
					
					<div class="form-group">
						<label>{{outlet_level==1?'补贴':'赠送'}}(元)</label>
						<input v-model="rechargeorder.gift" class="form-control form-control-lg" placeholder="输入金额" type="number">
						<small v-if="order_type==1">
							最多可退{{customer.gift_balance}}元
						</small>
					</div>
				   
				   <div class="form-group">
				   	<label>备注</label>
				   	<input v-model="rechargeorder.remark" class="form-control form-control-lg" placeholder="输入备注" type="text">
				   </div>
			   </div>
				
		      </div>
		      <div class="modal-footer">
		        <button type="button" class="btn btn-secondary" data-dismiss="modal">取消</button>
		        <button v-if="order_type==0" @click.stop="doRecharge" :disabled="disabled" type="button" class="btn btn-primary"> 确定充值 </button>
				<button v-if="order_type==1" @click.stop="doRecharge" :disabled="disabled" type="button" class="btn btn-danger"> 确定退款 </button>
		      </div>
		    </div>
		  </div>
		</div>
		<!-- /充值 -->
	</div>
</template>

<script>
	const __payment = {
	  'PC': '后台',
	  'WEAPP': '自助',
	}
	
	
	import DatePicker from 'vue2-datepicker';
	import 'vue2-datepicker/index.css';
	
	export default {
		data(){
			return{
				title: '会员资料',
				
				token: '',
				outlet_id: 0,
				outlet_name:'',
				user_name: '',
				outlet_level: 0,
				
				setting: {},
				outletlist: [],
				
				customer_id: 0,
				nonce_str: '',
				
				customer: {
				},
				selectedCustomer:{
					
				},
				
				rechargeorder:{
					amount: 0,
					gift: 0,
					times: 0,
					is_gift: 0,
					remark: '',
				},
				
				disabled:  false,
				
				orderlist: [],
				rechargeorderlist: [],
				
				customergrouplist: [],
				customergroup: {},
				
				tags: ['tag1', 'tag2', 'tag3'],
				
				order_type: 0,//订单类型：0充值订单，1退款订单
			}
		},
		
		components: {
			// 将组建加入组建库
			// 'tags-input': 'url:./tags-input.vue',
			DatePicker
		},
		
		created: function() {
			console.log('--- created --- ')
			let self = this;
			
			//1.检查登录
			let token = localStorage.getItem('TOKEN');
			if (!token) {
				return;
			}
			self.token = token;
			
			//2.取得店铺信息
			let lsLoginData = JSON.parse(localStorage.getItem('LOGINDATA'));
			console.log(lsLoginData.logined_outlet_name)
			// self.outlet_id = lsLoginData.logined_outlet_id;
			console.info('--- this.$store.state ->',this.$store.state)
			self.outlet_id = this.$store.state.current_outlet_id || lsLoginData.logined_outlet_id;
			self.outlet_name = lsLoginData.logined_outlet_name;
			self.user_name = lsLoginData.user_account;
			self.outlet_level = lsLoginData.outlet_level;
			
			self.setting = JSON.parse(localStorage.getItem('SETTING'));
			
			self.outletlist = JSON.parse(localStorage.getItem('OUTLETLIST'));
			console.log('--- outlet list ->', self.outletlist)
			
			// let id = getQueryString("id"); 
			// if(!id){
			// 	location.href = 'customers.html';
			// 	return;
			// }
			
			// console.error('--- this.$route @ customer ->',this.$route)
			let id = this.$route.query.id;
			
			let nonce_str = this.$route.query.nonce_str;
			
			
			if(!id){
				self.$router.push({ path: 'customers', params: { outlet_id: self.outlet_id }})
			}
			
			self.customer_id = id;
			self.nonce_str = nonce_str;
		},
		mounted: function() {
			console.log('--- mounted --- ')
			let self = this;
			
			//1.检查登录
			// let token = localStorage.getItem('TOKEN');
			// if (!self.token) {
			// 	console.log('--- mounted jump  --- ')
			// 	location.href = 'index.html';
			// 	return;
			// }
			
			self.initData(true);
		},
		methods: {
			initData(isFirst){
				let self = this;
				
				// 调用接口
				self.axios.get(self.GLOBAL.baseURI + 'getCustomer', {
					params: {
						token: self.token,
						outlet_id: self.outlet_id,
						customer_id: self.customer_id,
						nonce_str: self.nonce_str,
					}
				})
				.then(function(response) {
					console.log(response);
					return response.data;
				})
				.catch(function(error) {
					console.log(error);
				})
				.then(function(data) {
					// always executed
					console.log(data.data);
					if (data.code == 200) { 
						let customer = data.data;
						customer.img_url = customer.img_url==undefined?'assets/img/dummy/u6.png':customer.img_url
						customer.phone_number = customer.phone;
						self.customer = customer;
						self.selectedCustomer = Object.assign({},customer);
						
						// self.tags = customer.tags.split(",")
						
						if(isFirst){
							self.init();
						}
					}
					else if (data.code == 404) {
						 // Vue.toasted.error( '未找到商品', {
						 // 	position: 'top-center',
						 // 	duration: '2000',
						 // })
						 
						 self.$router.push({ name: 'page404'});
						 
						 return;
					}
					else if (data.code == 101) {
						//登录超时
						self.logoff();
					}
					else{
						// alert(data.message)
						Vue.toasted.error( data.message, {
							position: 'top-center',
							duration: '2000',
						})
					}
				});
				// END
				
			},
			
			init(){
			 	let self = this;
			 	
			 	//取得员工列表
			 	self.axios.get(self.GLOBAL.baseURI + 'getCustomerGroupList', {
			 		params: {
			 			token: self.token,
			 			outlet_id: self.outlet_id,
			 			// query: self.query,
			 			// current_page: self.current_page,
			 			// page_size: self.page_size,
			 		}
			 	})
			 	.then(function(response) {
			 		console.log(response);
			 		return response.data;
			 	})
			 	.catch(function(error) {
			 		console.log(error);
			 	})
			 	.then(function(data) {
			 		// always executed
			 		console.log(data.data);
			 		if (data.code == 200) { 
			 			self.customergrouplist = data.data;
			 			for(var i=0; i< self.customergrouplist.length; i++){
							if(self.customergrouplist[i].id == self.customer.group_id){
								self.customergroup = self.customergrouplist[i];
								console.log('--- customergroup->',self.customergroup)
								break;
							}
						}
			 		}
			 		else if (data.code == 101) { 
			 			//登录超时
			 			self.logoff();
			 		}
			 		else{
			 			// alert(data.message)
						self.$toasted.error(data.message, {
							position: 'top-center',
						}).goAway(2000)
			 		}
			 	});
			 	//END
			 	
			 },
			
			onstatechange(state){
				let self = this;
				let customer = self.customer;
				customer.enabled = state;
				self.customer = customer;
				
				self.axios.get(self.GLOBAL.baseURI + 'updateCustomerState', {
					params: {
						token: self.token,
						outlet_id: self.outlet_id,
						customer_id: self.customer.id,
						enabled: self.customer.enabled!=undefined?self.customer.enabled:1
					}
				})
				.then(function(response) {
					console.log(response);
					return response.data;
				})
				.catch(function(error) {
					console.log(error);
				})
				.then(function(data) {
					// always executed
					console.log(data.data);
					
					self.disabled = false;
					
					if (data.code == 200) {
						// alert('保存成功')
						// self.init();
					}
					else if (data.code == 101) {
						//登录超时
						self.logoff();
					}
					else{
						// alert(data.message)
						self.$toasted.error(data.message, {
							position: 'top-center',
						}).goAway(2000)
					}
				});
			},
			rechargeReady(ordertype){
				let self = this;
				
				self.rechargeorder = {
					amount: 0,
					gift: 0,
					times: 0,
					is_gift: 0,
					remark: '',
				}
				self.disabled = false;
				
				self.order_type = ordertype;
				
				$('#cModal').modal('show');
			},
			
			doRecharge(){
				let self = this;
				console.log(self.rechargeorder);
				// return;
				
				//校验
				if(!self.rechargeorder.amount && !self.rechargeorder.gift ){
					// alert('现金与赠送/补贴金额至少一个不为空')
					self.$toasted.error('现金与赠送/补贴金额至少一个不为空', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}
				
				if(self.setting.customer_balance_type == 1 && !self.rechargeorder.times){
					// alert('充值次数不能为空')
					self.$toasted.error('充值次数不能为空', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}
				
				//退款越界校验 >> 后端也需要
				if(self.order_type ==  1){
					if(self.rechargeorder.amount>self.customer.recharge_balance){
						// alert('退现金额不能超过现金余额')
						self.$toasted.error('退现金额不能超过现金余额', {
							position: 'top-center',
						}).goAway(2000)
						return;
					}
					
					if(self.rechargeorder.gift>self.customer.gift_balance ){
						// alert('退补金额不能超过补贴余额')
						self.$toasted.error('退补金额不能超过补贴余额', {
							position: 'top-center',
						}).goAway(2000)
						return;
					}
				}
				
				self.disabled = true;
				
				let pay = self.rechargeorder.amount || 0;//实付金额
				let gift = self.rechargeorder.gift || 0;//赠送/补贴金额
				let direct = self.order_type ==  1?-1:1;//退款为负数
				
				// 组装数据
				let postData = {
					token: self.token,
					outlet_id: self.outlet_id,
					customer_id: self.customer.id,
					amount: pay*direct,//实付金额
					gift: gift*direct,//赠送/补贴金额
					times: self.rechargeorder.times || 0,
					// is_gift: self.rechargeorder.is_gift?1:0,
					remark: self.rechargeorder.remark,
					order_type: self.order_type || 0,//订单类型：0为充值，1为退款
				}
				
				self.axios.get(self.GLOBAL.baseURI + 'rechargeCustomer', {
					params: postData
				})
				.then(function(response) {
					console.log(response);
					return response.data;
				})
				.catch(function(error) {
					console.log(error);
				})
				.then(function(data) {
					// always executed
					console.log(data.data);
					
					self.disabled = false;
					
					if (data.code == 200) {
						$('#cModal').modal('hide');
						
						// alert(self.order_type ==  1?'退款成功':'充值成功');
						self.$toasted.success(self.order_type ==  1?'退款成功':'充值成功', {
							position: 'top-center',
						}).goAway(2000)
						
						// self.init(false);
						self.initData(false);
					}
					else if (data.code == 101) {
						//登录超时
						$('#cModal').modal('hide');
						self.logoff();
					}
					else if (data.code == 4001) {
						// alert('会员不存在或已删除')
						self.$toasted.error('会员不存在或已删除', {
							position: 'top-center',
						}).goAway(2000)
					}
					else{
						alert(data.message)
					}
				});
			},
			
			save(){
				let self = this;
				console.log(self.selectedCustomer,$(".tags-input").tagsinput('items'));
				// return;
				
				//校验
				if(!self.selectedCustomer.customer_name){
					// alert('会员名不能为空')
					self.$toasted.error('会员名不能为空', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}
				
				if(!self.selectedCustomer.phone_number){
					// alert('手机号不能为空')
					self.$toasted.error('手机号不能为空', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}
				
				if(!self.selectedCustomer.customer_code){
					// alert('编号不能为空')
					self.$toasted.error('卡号不能为空', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}
				
				if(!self.selectedCustomer.customer_sn){
					// alert('卡号不能为空')
					self.$toasted.error('卡片芯片ID不能为空', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}
				
				let tags = $(".tags-input").tagsinput('items');
				let tag = tags.length>0?tags.join():'';
				
				if(tags.length > 5){
					// alert('会员标签最多5个')
					self.$toasted.error('会员标签最多', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}
				
				self.disabled = true;
				
				
				// 组装数据
				let postData = {
					token: self.token,
					outlet_id: self.outlet_id,
					customer_id: self.selectedCustomer.id,
					
					customer_name: self.selectedCustomer.customer_name,
					phone_number: self.selectedCustomer.phone_number,
					customer_code: self.selectedCustomer.customer_code,
					customer_sn: self.selectedCustomer.customer_sn,
					
					// balance: self.selectedCustomer.balance,
					group_id: self.selectedCustomer.group_id,
					password: self.selectedCustomer.password,
					
					expired_date: self.selectedCustomer.expired_date,
					enabled: self.selectedCustomer.enabled!=undefined?self.selectedCustomer.enabled:1,
					shared: self.selectedCustomer.shared,
					tag: tag,
				}
				
				self.axios.get(self.GLOBAL.baseURI + 'saveCustomer', {
					params: postData
				})
				.then(function(response) {
					console.log(response);
					return response.data;
				})
				.catch(function(error) {
					console.log(error);
				})
				.then(function(data) {
					// always executed
					console.log(data.data);
					
					self.disabled = false;
					
					if (data.code == 200) {
						// alert('保存成功')
						self.$toasted.success('保存成功', {
							position: 'top-center',
						}).goAway(2000)
						
						//location.href = 'customers.html'
						// self.init(false);
						let customer = data.data;
						customer.img_url = customer.img_url==undefined?'assets/img/dummy/u6.png':customer.img_url
						customer.phone_number = customer.phone;
						self.customer = customer;
						self.selectedCustomer = Object.assign({},customer);
					}
					else if (data.code == 101) {
						//登录超时
						self.logoff();
					}
					else if (data.code == 4001) {
						// alert('该手机号会员已存在')
						self.$toasted.error('该手机号会员已存在', {
							position: 'top-center',
						}).goAway(2000)
					}
					else{
						// alert(data.message)
						self.$toasted.error(data.message, {
							position: 'top-center',
						}).goAway(2000)
					}
				});
			},
			
			remove(){
				let self = this;
				console.log(self.customer);
				// return;
				
				self.disabled = true;
				
				// 组装数据
				let postData = {
					token: self.token,
					customer_id: self.customer.id,
				}
				
				self.axios.get(self.GLOBAL.baseURI + 'deleteCustomer', {
					params: postData
				})
				.then(function(response) {
					console.log(response);
					return response.data;
				})
				.catch(function(error) {
					console.log(error);
				})
				.then(function(data) {
					// always executed
					console.log(data.data);
					
					self.disabled = false;
					
					if (data.code == 200) { 
						// for(let i=0; i<self.customerlist.length; i++){
						// 	if(self.selectedIndex == i){
						// 		self.customerlist.splice(i,1);
						// 	}
						// }
						
						// alert('删除成功')
						
						// location.href="customers.html"
						
						self.$toasted.success('删除成功', {
							position: 'top-center',
						}).goAway(2000)
						
						self.$router.push({
							path: 'customers',
							params: {
								outlet_id: self.outlet_id
							}
						})
					}
					else if (data.code == 101) {
						//登录超时
						self.logoff();
					}
					else{
						// alert(data.message)
						self.$toasted.error(data.message, {
							position: 'top-center',
						}).goAway(2000)
					}
				});
			},
			
			
			getRechargeOrderList(){
				let self = this;
				console.log(self.customer);
				
				//START
				self.axios.get(self.GLOBAL.baseURI + 'getRechargeOrdersByCustomer', {
					params: {
						token: self.token,
						outlet_id: self.outlet_id,
						customer_id: self.customer.id,
					}
				})
				.then(function(response) {
					console.log(response);
					return response.data;
				})
				.catch(function(error) {
					console.log(error);
				})
				.then(function(data) {
					// always executed
					console.log(data.data);
					if (data.code == 200) { 
						self.rechargeorderlist = data.data;
					}
					else if (data.code == 101) {
						//登录超时
						self.logoff();
					}
					else{
						// alert(data.message)
						self.$toasted.error(data.message, {
							position: 'top-center',
						}).goAway(2000)
					}
				});
				//END
			},
			
			getOrderList(){
				let self = this;
				console.log(self.customer);
				
				//START
				self.axios.get(self.GLOBAL.baseURI + 'getCustomerOrderList', {
					params: {
						token: self.token,
						outlet_id: self.outlet_id,
						customer_id: self.customer.id,
					}
				})
				.then(function(response) {
					console.log(response);
					return response.data;
				})
				.catch(function(error) {
					console.log(error);
				})
				.then(function(data) {
					// always executed
					console.log(data.data);
					if (data.code == 200) { 
						self.orderlist = data.data;
					}
					else if (data.code == 101) {
						//登录超时
						self.logoff();
					}
					else{
						// alert(data.message)
						self.$toasted.error(data.message, {
							position: 'top-center',
						}).goAway(2000)
					}
				});
				//END
			},
			
			editCustomerReady(){
				console.log('--- tag->',$('.tags-input').val());
				// console.error('--- tag 2->',$('.tags-input').val(),$('.tags-input').tagsinput('items'));
				let self = this;
				
				//TAG初始化
				var ti = $('.tags-input');
				ti.tagsinput({
					tagClass: 'badge bg-primary',
						maxChars: 5
				})
				
				setTimeout(()=>{
					let tag = $('.tags-input').val();
					let tags = tag.split(",");
					// let tags = $('.tags-input').tagsinput('items');
					for(var i=0; i<tags.length; i++){
						ti.tagsinput('add', tags[i]);
					}
				},200)  
			},
			
			onOutletChangeListener(e) {
				let self = this;
				console.log('--- outlet ID->', e)
				self.outlet_id = e;
			
				self.initData(false);
			},
			
			errorLoadImg(){
				this.customer.img_url = require('../assets/img/dummy/u6.png');
			},
		},
	}
</script>

<style scoped>
	.bootstrap-tagsinput{
		width: 100% !important;
	}
	.bootstrap-tagsinput .tag{
		background-color: #03a9f4;
		padding: 6px 10px;
		font-weight: 300;
		display: inline-block;
		font-size: 75%;
		line-height: 1;
		text-align: center;
		white-space: nowrap;
		vertical-align: baseline;
	}
</style>
